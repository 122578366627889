import { getLeaderboard } from "api/transaction";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Spinner } from "react-bootstrap";
import moment from "moment";
import { CardBody } from "reactstrap";
import { numberFormatter } from "utils/number";
function TableList() {
  const [leaderboard, setLeaderboard] = useState([]);

  const loadHistory = async () => {
    try {
      const { data } = await getLeaderboard();
      setLeaderboard(data.resellers);
    } catch (error) {}
  };

  useEffect(() => {
    loadHistory();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12} className="mb-4">
            <h3>This month ranking</h3>
          </Col>

          {leaderboard?.map((i, idx) => (
            <Col sm={12}>
              <Card>
                <CardBody style={{ display: "flex", alignItems: "center" }}>
                  <img className="me-2" src={`https://ui-avatars.com/api/?name=${idx + 1}`} style={{ width: 50, height: 50 }} />

                  <div style={{ flexGrow: 1 }}>
                    <div>{i?.sender?.name}</div>
                    <div>ID: {i?.sender?.unique_id}</div>
                  </div>

                  <div>
                    <strong>{numberFormatter(i?.total)}</strong>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default TableList;
